<template>
  <div class="content">
    <div class="content-top">
      <!-- 面包屑 -->
      <div class="breadcrumbs">
        <i></i>
        <a href="/">首页</a><span>></span>
        <a href="#" class="active">养老科普</a>
      </div>
      <!-- 侧边栏 + 头部内容 -->
      <div class="top-sidebar">
        <!-- 侧边栏 -->

        <div class="fl fw">
          <div class="one">
            <img class="one_img" src="/static/img/icon_title.png" alt="" />
            <span class="one_span">养老科普</span>
          </div>
          <div class="h_div"></div>
          <ul class="two" style="padding-top: 10px; padding-bottom: 10px">
            <li
              class="two_li"
              v-for="(item, index) in list"
              :key="index"
              @click="handleDetail(item)">
              <span id="content">
                <a>{{ item.title.length > 41 ? item.title.slice(0,41) + '...' : item.title }}</a></span>
              <p>{{ item.create_time }}</p>
            </li>
          </ul>
          <div class="pages">
            <a class="prev_btn" v-if="isfirst" @click="handlePage(curpage - 1)">上一页</a>
            <a
              class="num"
              v-for="(pindex, index) in pagelist"
              :key="index"
              v-bind:class="{ act: curpage == pindex }"
              @click="handlePage(pindex)" >{{ pindex }}</a>
            <!-- <a class="num" v-bind:class="{act:curpage == totalpage}" @click="handlePage(totalpage)">{{ totalpage }}</a> -->
            <a class="next_btn" v-if="islatest" @click="handlePage(curpage + 1)">下一页</a>
          </div>
        </div>
        <div class="right sidebar">
          <ul>
            <li>
              <div class="sidebar-title active">
                <span>文章中心</span>
              </div>
              <!-- 子菜单 -->
              <ul class="sidebar-health">
                <li id="ylkp" class="health-active">养老科普</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div style="clear: both"></div>
</template>

<style src="../../../public/static/css/claim-download.css" />
<style src="../../../public/static/css/news.css" />
<style>
body {
  background: #f6f5f4 !important;
}
.fw{width:calc(100% - 207px);}
.two {
  padding-top: 10px;
  padding-bottom: 10px;
  font: 18px/34px Arial, Hiragino Sans GB, STHeiti, Helvetica Neue, Helvetica,
    Microsoft Yahei, WenQuanYi Micro Hei, sans-serif;
  color: #404040;
}

.meta {
  color: #999 !important;
  font-size: 15px;
  margin-top: 5px !important;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
</style>

<script>
import * as Api from "@/api";
export default {
  data() {
    return {
      curpage: 1,
      totalpage: 0,
      perpage: 0,
      totalcount: 0,
      list: [],
      isfirst: false,
      islatest: false,
      morethan5: false,
      pagelist: [],
    };
  },
  created() {
    this.loadData(1);
  },
  methods: {
    loadData(topage) {
      Api.posts({ page: topage }).then((response) => {
        //return response.data.list
        var res = response.data.data.list;
        console.log(res);
        this.curpage = res.current_page;
        this.totalcount = res.total;
        this.perpage = res.per_page;
        this.totalpage = res.last_page;
        this.list = res.data;
        this.isfirst = this.curpage > 1;
        this.islatest = this.curpage < this.totalpage;
        let start = this.curpage - 2 <= 0 ? 1 : this.curpage - 2;
        let end =
          this.curpage + 2 >= this.totalpage
            ? this.totalpage
            : this.curpage + 2;

        this.pagelist = [];
        for (let i = start; i <= end; i++) {
          this.pagelist.push(i);
        }
      });
    },
    handleDetail(item) {
      this.$router.push({ name: "PostsDetail", params: { id: item.article_id } });
    },
    handlePage(topage) {
      this.loadData(topage);
    },
  },
};
</script>
